import { LinkListResponseData } from '@/interface/link';
import { NextApiResponseData } from '@/interface/request';
import { nextApiRequest } from '@/utils/request';

export async function getLinksList() {
  return nextApiRequest.request<NextApiResponseData<LinkListResponseData>, NextApiResponseData<LinkListResponseData>>({
    method: 'get',
    url: '/content/portal/friendshiplink',
  });
}
