import React from 'react';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { useRequest } from 'ahooks';
import { TEL_MOBILE } from '@/constants/global';
import { getLinksList } from '@/services/link';
import CBNDataLogo from 'assets/cbndata_logo.svg';
import styles from './index.module.scss';

export interface IFooterProps {
  className?: string;
}

export function FooterNormal({ className }: IFooterProps) {
  return (
    <footer className={classNames(styles.footer, className)}>
      <div>如有任何疑问，请咨询平台工作人员</div>
      <div>{TEL_MOBILE}</div>
    </footer>
  );
}

export interface ICopyRightProps {
  isWrap?: boolean;
  center?: boolean;
  className?: string;
}

export function CopyRight({ isWrap = false, center, className }: ICopyRightProps) {
  return (
    <p className={classNames(styles.copyRight, className, { 'text-center': center || isWrap })}>
      &copy;上海第一财经数据科技有限公司 {isWrap && <br />}沪ICP备15026457号-5
    </p>
  );
}

const qrcodeOptions = [
  {
    render: () => (
      <svg className="icon" aria-hidden="true">
        <use xlinkHref="#icon-weibo"></use>
      </svg>
    ),
  },
  {
    render: () => (
      <svg className="icon" aria-hidden="true">
        <use xlinkHref="#icon-wechat"></use>
      </svg>
    ),
  },
  {
    render: () => (
      <svg className="icon" aria-hidden="true">
        <use xlinkHref="#icon-a-miniprogramicon"></use>
      </svg>
    ),
  },
];

export const QrcodeContent = index => {
  switch (index) {
    case 0:
      return (
        <div className={classNames('flex-row-nowrap', 'flex-center-center', styles.qrcodeSection)}>
          <div className={classNames('flex-column-nowrap', 'flex-center-center', styles.qrcodeContent)} key={index}>
            <img width="80px" src="//assets-oss.cbndata.com/cbndata-web/logo/FroP3gm0CNRKJuX44pPlAoSD8v2N.png" />
            <div className={styles.codeItemName}>@第一财经商业数据中心</div>
          </div>
          {/* <div className={classNames('flex-column-nowrap', 'flex-center-center', styles.qrcodeContent)} key={index}>
            <img width="80px" src="//assets-oss.cbndata.com/cbndata-web/logo/FnKSL6t1dFNp7xxdpDtO_mO3PmS3.png" />
            <div className={styles.codeItemName}>@第一财经商业数据中心</div>
          </div> */}
        </div>
      );
    case 1:
      return (
        <div className={classNames('flex-row-nowrap', 'flex-center-center', styles.qrcodeSection)}>
          <div className={classNames('flex-column-nowrap', 'flex-center-center', styles.qrcodeContent)} key={index}>
            <img
              width="80px"
              height="80px"
              src="//assets-oss.cbndata.com/cbndata-refactor-fe/FnigHdyYQ2uZrY5JFGtMvK0HeId-.png"
            />
            <div className={styles.codeItemName}>@CBNData消费站</div>
          </div>
          <div className={classNames('flex-column-nowrap', 'flex-center-center', styles.qrcodeContent)} key={index}>
            <img
              width="80px"
              height="80px"
              src="//assets-oss.cbndata.com/cbndata-refactor-fe/FnigHdyYQ2uZrY5JFGtMvK0HeId-.png"
            />
            <div className={styles.codeItemName}>@第一财经商业数据中心</div>
          </div>
          <div className={classNames('flex-column-nowrap', 'flex-center-center', styles.qrcodeContent)} key={index}>
            <img
              width="80px"
              height="80px"
              src="//assets-oss.cbndata.com/cbndata-refactor-fe/FmuJb6KYxvd5zLF0hROdM4GNkFqB.png"
            />
            <div className={styles.codeItemName}>@消费新探</div>
          </div>
          <div className={classNames('flex-column-nowrap', 'flex-center-center', styles.qrcodeContent)} key={index}>
            <img
              width="80px"
              height="80px"
              src="//assets-oss.cbndata.com/cbndata-refactor-fe/Fk2fQjScRIC1VTOEYeB-_VMlYNjJ.png"
            />
            <div className={styles.codeItemName}>@星数</div>
          </div>
          <div className={classNames('flex-column-nowrap', 'flex-center-center', styles.qrcodeContent)} key={index}>
            <img
              width="80px"
              height="80px"
              src="//assets-oss.cbndata.com/cbndata-refactor-fe/Fg5VaUYDdKocnxt_xszOzXqFcmMK.png"
            />
            <div className={styles.codeItemName}>@红漏斗</div>
          </div>
        </div>
      );
    case 2:
      return (
        <div className={classNames('flex-row-nowrap', 'flex-center-center', styles.qrcodeSection)}>
          <div className={classNames('flex-column-nowrap', 'flex-center-center', styles.qrcodeContent)} key={index}>
            <img
              width="80px"
              height="80px"
              src="//assets-oss.cbndata.com/cbndata-web/Downloads/FqgX0H1Q4Oa4sENGG3rnOtKalxHt.jpg"
            />
            <div className={styles.codeItemName}>@CBNDatabox</div>
          </div>
        </div>
      );
    default:
      return <></>;
  }
};

const ConactUs = () => (
  <div className={styles.ConactUs}>
    <CBNDataLogo />
    <div className={classNames('flex-row-nowrap', 'flex-start-center', styles.qrcodesContainer)}>
      {qrcodeOptions.map((item, index) => (
        <Tooltip
          placement="topLeft"
          trigger={['hover']}
          overlay={QrcodeContent(index)}
          overlayClassName={styles.footerTooltip}
          key={index}
        >
          <div className={classNames(styles.qrcodeFocus)}>{item.render()}</div>
        </Tooltip>
      ))}
    </div>
  </div>
);

const Linklist = () => (
  <div className={classNames('flex-row-nowrap', 'flex-between-start', styles.Linklist)}>
    <div className={styles.LinklistContent}>
      <span className={styles.LinklistTitle}>关于CBNData</span>
      <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/about?type=company_profile" target="_blank">
          公司介绍
        </a>
      </div>
      <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/about?type=business_introduction" target="_blank">
          业务介绍
        </a>
      </div>
      <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/about?type=team_instoduction" target="_blank">
          团队介绍
        </a>
      </div>
      <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/about?type=partners" target="_blank">
          合作伙伴
        </a>
      </div>
    </div>
    <div className={styles.LinklistContent}>
      <span className={styles.LinklistTitle}>关于应物白</span>
      <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/about?type=brand_description" target="_blank">
          品牌概述
        </a>
      </div>
      <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/about?type=product_address" target="_blank">
          产地及产品
        </a>
      </div>
      <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/about?type=brand_story" target="_blank">
          品牌故事
        </a>
      </div>
      <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/about?type=brand_achievement" target="_blank">
          品牌成绩
        </a>
      </div>
    </div>
    <div className={styles.LinklistContent}>
      <span className={styles.LinklistTitle}>联系我们</span>
      {/* <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/enterprise" target="_blank">
          企业服务
        </a>
      </div> */}
      <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/about?type=business_cooperation" target="_blank">
          业务合作
        </a>
      </div>
      <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/about?type=join_us" target="_blank">
          加入我们
        </a>
      </div>
    </div>
    <div className={styles.LinklistContent}>
      <span className={styles.LinklistTitle}>特别说明</span>
      <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/copyright" target="_blank">
          版权说明
        </a>
      </div>
      <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/agreement" target="_blank">
          用户协议
        </a>
      </div>
      <div className={styles.LinklistItem}>
        <a className={styles.LinklistItemLink} href="/disclaimer" target="_blank">
          免责声明
        </a>
      </div>
    </div>
  </div>
);

const Links = ({ linksList }) => (
  <div className={styles.Links}>
    <h3 className={styles.LinksTitle}>友情链接</h3>
    <div className={classNames('flex-row-wrap', styles.LinksList)}>
      {linksList?.slice(0, 12).map((item, index) => (
        <a
          className={styles.LinksListItem}
          key={index}
          href={item.link}
          target="_blank"
          rel="noreferrer"
          aria-label={item.title}
        >
          <img src={item.cover} alt={item.title} />
        </a>
      ))}
    </div>
  </div>
);

export default function Footer() {
  const { data } = useRequest(
    // const { data, loading, loadingMore, reload, loadMore, noMore } = useRequest(
    () => {
      return getLinksList();
    },
    {
      formatResult(res) {
        if (res.code === 200) {
          return res.data.list;
        }
        return [];
      },
    }
  );
  return (
    <footer className={classNames('flex-column-nowrap', 'flex-between-start', styles.footerSection)}>
      <div className={classNames('flex-row-nowrap', styles.footerContent)}>
        <ConactUs />
        <Linklist />
        <Links linksList={data} />
      </div>
      <div className={styles.record}>
        <a className={styles.recordLink} href="/copyright" target="_blank">
          ©上海应帆数字科技有限公司
        </a>
        <span> | </span>
        <a className={styles.recordLink} href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
          沪ICP备15026457号-5
        </a>
      </div>
    </footer>
  );
}
